import {
	type LinkPickerPlugin,
	type LinkPickerPluginAction,
	type LinkPickerState,
	type LinkSearchListItemData,
} from '@atlaskit/link-picker';
import { filterUniqueItems, promiseDebounce } from '@atlaskit/linking-common/utils';
import { fg } from '@atlaskit/platform-feature-flags';
import { type ActivityClient, createActivityClient } from '@atlassian/recent-work-client';
import { Scope } from '@atlassian/search-client';

import SearchResource from './services/searchResource';
import { type OptionalKeysOf, type QuickSearchResult, type SearchProvider } from './types';
import { renderEmptyStateNoResults } from './ui';
import {
	filterItems,
	getArisForProducts,
	mapActivityClientResultToLinkSearchItemData,
	mapSearchProviderResultToLinkSearchItemData,
} from './utils';

export const RECENT_SEARCH_LIST_SIZE = 5;
const DEBOUNCE_MS = 400;
export interface TabConfig {
	/**
	 * Unique identifier of the tab.
	 */
	tabKey: string;
	/**
	 * The title that gets displayed
	 */
	tabTitle: string;
}

export interface AtlassianLinkPickerPluginConfig {
	/**
	 * Tab specific configuration.
	 */
	tabConfig?: TabConfig;

	/**
	 * The cloud ID of the instance.
	 */
	cloudId?: string;
	/**
	 * The cloud IDs used for multi-site searching. Overrides cloudId.
	 * This is currently being experimented with in Trello and is not yet ready
	 * for broader consumption!
	 */
	cloudIds?: string[];
	/**
	 * The list of products to query for from the activityclient
	 * supported values are jira, confluence, townsquare
	 * https://developer.atlassian.com/platform/recent-work/developer-guide/tab-configuration/#filters
	 */
	products?: string[];
	/**
	 * The scope of the search provider (e.g. Scope.ConfluencePageBlog)
	 * Exported as part of the plugin.
	 *
	 * Referenced from Scope enum from @atlassian/search-provider.
	 */
	scope?: Scope;
	/**
	 * The URL of the agreggator for search provider.
	 */
	aggregatorUrl?: string;
	/**
	 * @deprecated Use cloudId, scope and aggregatorUrl instead.
	 * The search provider.
	 */
	searchProvider?: Promise<SearchProvider>;
	/**
	 * Whether to disable the activity client.
	 */
	disableActivityClient?: boolean;
	/**
	 * The endpoint of the activity client.
	 */
	activityClientEndpoint?: string;
	/**
	 * Plugin actions
	 */
	action?: LinkPickerPluginAction;
	/**
	 * Optional filter-function to filter items based on ARI.
	 * @deprecated Use `dataFilter` instead.
	 */
	itemFilter?: LinkPickerPluginItemFilter;
	/**
	 * Optional filter-function to filter items based on specific data properties, e.g. id, url.
	 */
	dataFilter?: LinkPickerPluginDataFilter;
	/**
	 * Optionally allow recents from multiple sites (based on cloudIds)
	 */
	allowMultiSiteRecents?: boolean;
}

type LinkPickerPluginItemFilter = (itemAri: string) => boolean;
type LinkPickerPluginDataFilterItem = {
	/** Unique identifiable attribute for the item in ARI format */
	id: string;
	/** URL of the resource being linked to */
	url: string;
};
type LinkPickerPluginDataFilter = (item: LinkPickerPluginDataFilterItem) => boolean;

/**
 * Plugin responsible for handling fetching + caching of Atlassian Link Picker results
 */
class AtlassianLinkPickerPlugin implements LinkPickerPlugin {
	private _tabKey: string;
	private _tabTitle: string;
	private products?: string[];
	private debouncedSearch: (query: string) => Promise<QuickSearchResult[]>;
	private searchProvider?: Promise<SearchProvider>;
	private activityClient?: ActivityClient;
	private recentItems?: LinkSearchListItemData[];
	private analyticsIdentifier: string = 'link-picker-quick-search';
	private useConfluenceTypeInAri: boolean = true;
	private cloudId?: string;
	private cloudIds?: string[];
	private isMultisiteSearch: boolean;
	private allowMultiSiteRecents?: boolean;
	private _action?: LinkPickerPluginAction;
	private _scope?: Scope;
	private _loomRecentItems?: LinkSearchListItemData[];
	private _pluginItemFilter?: LinkPickerPluginItemFilter;
	private _pluginDataFilter?: LinkPickerPluginDataFilter;

	constructor(config?: AtlassianLinkPickerPluginConfig) {
		const {
			tabConfig,
			activityClientEndpoint,
			disableActivityClient = false,
			searchProvider,
			cloudId,
			cloudIds,
			scope,
			products,
			aggregatorUrl,
			action,
			itemFilter,
			dataFilter,
			allowMultiSiteRecents,
		} = config ?? {};
		const { tabKey, tabTitle } = tabConfig ?? {};

		this._tabKey = tabKey || 'Atlassian';
		this._tabTitle = tabTitle || 'Atlassian';
		this.products = products;
		this.cloudId = cloudId;
		this.cloudIds = cloudIds;
		this.isMultisiteSearch = (this.cloudIds?.length ?? 0) > 0;
		this.allowMultiSiteRecents = this.isMultisiteSearch && allowMultiSiteRecents;
		this._action = action;
		this._scope = scope;
		this._pluginItemFilter = itemFilter;
		this._pluginDataFilter = dataFilter;
		this.searchProvider =
			searchProvider ||
			this.createSearchProvider(
				scope,
				aggregatorUrl,
				this.analyticsIdentifier,
				this.useConfluenceTypeInAri,
			);
		if (!disableActivityClient) {
			this.activityClient = createActivityClient(
				'v3',
				'link_picker_atlassian_plugin',
				undefined,
				activityClientEndpoint,
			);
		}

		this.debouncedSearch = promiseDebounce(async (query: string) => {
			if (!this.searchProvider) {
				return [];
			}
			const searchProvider = await this.searchProvider;

			return searchProvider.quickSearch(query, RECENT_SEARCH_LIST_SIZE);
		}, DEBOUNCE_MS);
	}

	/**
	 * Create search provider if config params are provided
	 */
	private createSearchProvider(
		scope?: Scope,
		aggregatorUrl?: string,
		analyticsIdentifier?: string,
		useConfluenceTypeInAri?: boolean,
	) {
		if (!this.cloudId || !scope) {
			return undefined;
		}
		return Promise.resolve(
			new SearchResource(
				this.cloudId,
				scope,
				aggregatorUrl,
				analyticsIdentifier,
				useConfluenceTypeInAri,
				this.cloudIds,
			),
		);
	}

	/**
	 * Filter out fields from recent or search items.
	 * @param items The recent items to remove fields from.
	 * @returns Items with fields removed.
	 */
	private filterListItemFields(items: LinkSearchListItemData[]): LinkSearchListItemData[] {
		// Assume this.products won't change during function call
		const products = this.products;

		// We can't filter out items if we don't have a product or we have more than one product,
		// as we can't tell which product a given item is from.
		if (products?.length !== 1) {
			return items;
		}

		// Config of map of products to fields to filter out from each item
		const productFieldsMap: Record<string, OptionalKeysOf<LinkSearchListItemData>[]> = {
			townsquare: ['lastViewedDate', 'lastUpdatedDate', 'container'],
		};

		return items.map((item) => {
			const newItem: LinkSearchListItemData = { ...item };

			// Set fields to be undefined when they have been specified to be based on a given product in productFieldsMap
			for (const [productToCheck, fieldsToCheck] of Object.entries(productFieldsMap)) {
				if (products[0] === productToCheck) {
					for (const field of fieldsToCheck) {
						newItem[field] = undefined;
					}
				}
			}
			return newItem;
		});
	}

	/**
	 * Set lastUpdatedDate for Loom search results and unset lastViewedDate.
	 * @param items The recent Loom items .
	 * @returns Items with lastUpdatedDate set.
	 */
	private setLoomRecentItemsDateField(items: LinkSearchListItemData[]): LinkSearchListItemData[] {
		return items.map((item) => {
			const newItem: LinkSearchListItemData = { ...item };

			newItem.lastViewedDate = item.lastUpdatedDate;
			newItem.lastUpdatedDate = undefined;

			return newItem;
		});
	}

	/**
	 * Retrieve recent items from the activity provider
	 */
	private async getRecentItems() {
		if (!this.activityClient) {
			return [];
		}

		if (!this.recentItems) {
			const { viewed: items } = await this.activityClient.fetchActivities(
				[50, 125, 250], // retries
				{
					limit: 100,
					products: this.products,
					rootContainerIds: this.allowMultiSiteRecents
						? this.cloudIds?.reduce(
								(acc, cloudId) => acc.concat(...getArisForProducts(this.products, cloudId)),
								[] as string[],
							)
						: // If cloudIds array is provided, do not scope activities query
							this.cloudId !== undefined && !this.isMultisiteSearch
							? getArisForProducts(this.products, this.cloudId)
							: undefined,
				},
				['viewed'],
				true,
			);

			this.recentItems = items.map(mapActivityClientResultToLinkSearchItemData);
			this.recentItems = this.filterListItemFields(this.recentItems);
		}

		return this.recentItems;
	}

	/**
	 * Given a query, search the activity provider for recent activity
	 */
	private async searchRecentItems({ query }: LinkPickerState): Promise<LinkSearchListItemData[]> {
		if (!this.recentItems) {
			await this.getRecentItems();
		}

		return filterItems(this.recentItems, query);
	}

	private async quickSearch(state: LinkPickerState): Promise<LinkSearchListItemData[]> {
		const isLoomRecentSearch = this._scope === Scope.Loom && state.query.length === 0;

		// similar to getRecentItems() we return the initial recent result when query string is reset
		if (isLoomRecentSearch && this._loomRecentItems) {
			return this._loomRecentItems;
		}

		const items = await this.debouncedSearch(state.query);

		let searchListItems = items.map(mapSearchProviderResultToLinkSearchItemData);

		// For Loom both recent and search results are fetched from search client. When search query is empty
		// the search returns recent items, when the query is not empty it returns searched items.
		// The setLoomRecentItemsDateField method sets the correct date field for the UI to consume.
		if (isLoomRecentSearch) {
			searchListItems = this.setLoomRecentItemsDateField(searchListItems);
			this._loomRecentItems = searchListItems;
		}

		return this.filterListItemFields(searchListItems);
	}

	/**
	 * Merge list of recent items and search items, and dedupes
	 */
	private mergeResults(itemsA: LinkSearchListItemData[], itemsB: LinkSearchListItemData[]) {
		// Dedupe the items based on the URL
		return filterUniqueItems([...itemsA, ...itemsB], (itemA, itemB) => itemA.url === itemB.url);
	}

	private applyPluginFilter(items: LinkSearchListItemData[]) {
		if (fg('jsc_inline_editing_field_refactor')) {
			return items
				.filter(({ objectId }) => this._pluginItemFilter?.(objectId) ?? true)
				.filter(({ objectId, url }) => this._pluginDataFilter?.({ id: objectId, url }) ?? true);
		}
		return this._pluginItemFilter
			? items.filter(({ objectId }) => this._pluginItemFilter?.(objectId))
			: items;
	}

	/**
	 * Given link picker state yields updates to the list of link picker results
	 * The plugin is considered to be in a loading state until the generator returns a final value
	 * The plugin can yield intermediate lists of results to present the user with initial results
	 * while loading additional results to update the list with.
	 */
	async *resolve(state: LinkPickerState) {
		if (this.activityClient && state.query.length === 0) {
			return { data: this.applyPluginFilter(await this.getRecentItems()) };
		}

		// Filter recents based on current state
		const filteredRecentItems = this.applyPluginFilter(await this.searchRecentItems(state));

		// If we have enough results, or there's no search provider to fetch more, return what we have
		if (filteredRecentItems.length >= RECENT_SEARCH_LIST_SIZE || !this.searchProvider) {
			return { data: filteredRecentItems };
		}

		if (filteredRecentItems.length) {
			// Update with what we have while we search for more
			yield { data: filteredRecentItems };
		}
		// Get more results and return them merged with recents
		const searchResults: LinkSearchListItemData[] = this.applyPluginFilter(
			await this.quickSearch(state),
		);
		return {
			data: this.mergeResults(filteredRecentItems, searchResults),
		};
	}

	emptyStateNoResults() {
		if (fg('link-picker-atl-plugin-empty-state')) {
			if (!this.activityClient) {
				return null; // recent activity isn't supported, so leave this as blank
			}

			if (!this.products || this.products.length !== 1) {
				// don't support multi-product, as message gets too confusing
				return null;
			}

			return renderEmptyStateNoResults(
				this.products[0],
				this.action,
				this.cloudId,
				this.isMultisiteSearch,
			);
		}

		return null;
	}

	get tabKey() {
		return this._tabKey;
	}

	get tabTitle() {
		return this._tabTitle;
	}

	get action() {
		return this._action;
	}
}

export default AtlassianLinkPickerPlugin;
